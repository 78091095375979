import { graphql } from "gatsby";
import SEO from "@components/seo";
import React from "react";
import { css } from "@emotion/react";
import Image from "gatsby-image";
import { Section, Div, Container, RowsNine } from "@components/Markup";
import PublicationHeader from "@components/PublicationHeader";
import PrevNextNav from "@components/PrevNextNav";
import { Text } from "@components/Typography";
import Share from "@components/Share";

const PostThumbnail = ({ featuredImage }) => {
  return (
    <div
      css={css`
        display: block;
        transition: 0.15s all;
        margin-bottom: 16px;
        position: relative;
      `}
    >
      <Image fluid={featuredImage ? featuredImage.fluid : null} css={css``} />
    </div>
  );
};

const Publication = ({ data }) => {
  const post = data?.post;
  const nextPost = data?.nextPost;
  const prevPost = data?.prevPost;

  const {
    title,
    description,
    featuredImage,
    author,
    publisher,
    year,
    isbn,
  } = post;

  const seoDisplayTitle = `${title} | Hermann & Valentiny und Partner Architekten | Publikationen`;
  const seoDisplayImage = featuredImage ? featuredImage.fluid.src : null;
  const seoDisplayDescription = description;

  return (
    <React.Fragment>
      <SEO
        title={seoDisplayTitle}
        image={seoDisplayImage}
        description={seoDisplayDescription}
      />
      <Section pt="lg" pb="lg">
        <Container>
          <RowsNine>
            <PublicationHeader title={title} />
            <Div
              css={css`
                position: relative;
              `}
            >
              <Share />
              <PostThumbnail featuredImage={featuredImage} />
            </Div>
          </RowsNine>
          <Div>
            <Text>{description}</Text>
          </Div>
          <Div>
            <br />
            <br />
            <Text>details</Text>
            <ul>
              <li>author: {author}</li>
              <li>year: {year}</li>
              <li>publisher: {publisher}</li>
              <li>isbn: {isbn}</li>
            </ul>
          </Div>
        </Container>
        {prevPost && nextPost && (
          <PrevNextNav
            prev={{
              featuredImage: prevPost.featuredImage,
              name: prevPost.title,
              url: `/publikationen/${prevPost.slug}`,
            }}
            next={{
              featuredImage: nextPost.featuredImage,
              name: nextPost.title,
              url: `/publikationen/${nextPost.slug}`,
            }}
          />
        )}
      </Section>
    </React.Fragment>
  );
};

export default Publication;

export const query = graphql`
  query PublicationQuery(
    $slug: String!
    $nextSlug: String!
    $prevSlug: String!
  ) {
    nextPost: datoCmsPublikation(slug: { eq: $nextSlug }) {
      slug
      title
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    prevPost: datoCmsPublikation(slug: { eq: $prevSlug }) {
      slug
      title
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    post: datoCmsPublikation(slug: { eq: $slug }) {
      title
      description
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
      author
      year
      publisher
      isbn
    }
  }
`;
