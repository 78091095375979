import { Link } from "gatsby";
import styled from "@emotion/styled";
import React from "react";
import { css } from "@emotion/react";
import { Title } from "@components/Typography";

const H = styled.header`
  width: 100%;
  margin-bottom: 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 767px) {
    h2 {
      margin-bottom: 16px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const PostHeader = ({ title, slug }) => {
  return (
    <React.Fragment>
      {slug !== undefined && (
        <Link
          to={`/blog/${slug}`}
          css={css`
            transition: 0.15s all;
            &:hover {
              opacity: 0.75;
              cursor: pointer;
            }
          `}
        >
          <H>
            <Title
              tag="h2"
              size="lg"
              css={css`
                max-width: 640px;
                transition: 0.15s all;
                text-decoration: none;
              `}
            >
              {title}
            </Title>
          </H>
        </Link>
      )}
      {!slug && (
        <H>
          <Title
            tag="h2"
            size="lg"
            css={css`
              max-width: 640px;
              transition: 0.15s all;
              text-decoration: none;
            `}
          >
            {title}
          </Title>
        </H>
      )}
    </React.Fragment>
  );
};

export default PostHeader;
